<template>
  <div>
    <h2>欢迎登陆</h2>
    <h2>甘谷县物流管理系统</h2>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
div {
  margin: 300px auto;
  text-align: center;
  font-size: 30px;
  color: rgb(221, 49, 49);
}
</style>
